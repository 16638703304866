import { truncate, dateFormater } from '@/filters'

export default {
  name: 'BannerItem',
  filters: {
    truncate,
    dateFormater
  },
  props: {
    banner: {
      type: Object,
      default: {}
    }
  },
  computed: {
    bannerValue () {
      if (this.banner.type === 'DEFAULT') {
        return this.banner.redirectUrl
      }
      return this.banner.youtubeVideoId
    }
  },
  methods: {
    handleDeleteBanner () {
      this.$emit('deleteBanner', this.banner.id)
    }
  }
}
